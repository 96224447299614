import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';
import * as actions from '@actions';
import * as actionTypes from '@actionTypes';
import Link from 'next/link';
import { DownOutlined, UserOutlined, FileTextOutlined, LogoutOutlined, MenuOutlined, ReloadOutlined } from 'antd/icons';
import { Dropdown, Avatar, Button, MenuProps } from 'antd';
import useAvatar from 'vl-common/src/hooks/useAvatar';
import { useQueryClient } from '@tanstack/react-query';
import useTheme from '@hooks/useTheme';
import Head from 'next/head';
import { useIsStandalone } from '@hooks/useSafariPrompts';
import { css } from '@emotion/react';
import PleaseLogIn from '@components/Modal/PleaseLogIn';
import { usePleaseLogIn, usePleaseLogInAndRedirect } from '@hooks/usePleaseLogIn';
import useIsRestricted from '@hooks/useIsRestricted';
import useAuth from '@vl-core/useAuth';
import usePolicies from '@hooks/usePolicies';
import { ConfirmationModal } from 'vl-common/src/components/Modals';
import { useExtremeZoom } from '@hooks/useZoom';
import { UnstyledButton } from 'vl-common/src/components/UnstyledButton';
import { useDeepLink } from '@hooks/useDeepLink';
import { requestSuccess } from '../../../store/actions/action';
import { NavigationHeader } from './NavigationHeader';

type MenuDefinition = {
  name: string;
  link: string;
  restricted?: boolean;
  testid: string;
};

type PageType = 'patients' | 'clinician' | 'login';

const menuList: Record<PageType, MenuDefinition[]> = {
  clinician: [
    {
      name: 'Dashboard',
      link: '/clinician',
      testid: 'clinician-dashboard-link'
    },
    {
      name: 'Appointments',
      link: '/clinician/appointments',
      testid: 'clinician-appointments-link'
    },
    {
      name: 'Cases',
      link: '/clinician/cases',
      testid: 'clinician-cases-link'
    },
    {
      name: 'Patients',
      link: '/clinician/patients',
      testid: 'clinician-patients-link'
    },
    {
      name: 'Reports',
      link: '/clinician/reports',
      testid: 'clinician-reports-link'
    },
    {
      name: 'Support',
      link: '/clinician/support',
      testid: 'clinician-support-link'
    }
  ],
  patients: [
    {
      name: 'Dashboard',
      link: '/patients',
      testid: 'patients-dashboard-link'
    },
    {
      name: 'Appointments',
      link: '/patients/appointments',
      restricted: true,
      testid: 'patients-appointments-link'
    },
    {
      name: 'Support',
      link: '/patients/support',
      testid: 'patients-support-link'
    }
  ],
  login: []
};

const mobileNavMenuDropdownMenu = (
  menu: PageType,
  isRestricted: boolean,
  setModalOpen: (s: boolean) => void
): MenuProps['items'] => {
  return [
    ...menuList[menu].map((item, index) => ({
      key: index,
      label:
        item.restricted && isRestricted ? (
          <UnstyledButton
            key={item.link}
            className="mobile-nav-link"
            onClick={() => setModalOpen(true)}
            data-testid={item.testid}
          >
            {item.name}
          </UnstyledButton>
        ) : (
          <Link href={item.link} className="mobile-nav-link" data-testid={item.testid}>
            {item.name}
          </Link>
        )
    }))
  ];
};

const navMenuStyles = css`
  display: flex;
  flex-basis: 100%;
  justify-content: flex-end;

  /* Centre the nav content above 1024px */
  @media (min-width: 1024px) {
    justify-content: center;
  }

  .desktop {
    /* Hide the desktop nav at small sizes */
    display: none;

    /* Show the desktop nav above 1024px */
    @media (min-width: 1024px) {
      display: block;
    }
  }
  .mobile {
    /* Show the mobile nav at small sizes */
    display: block;

    /* Hide the mobile nav above 1024px */
    @media (min-width: 1024px) {
      display: none;
    }
  }
  .hamburger {
    color: var(--black);
    font-size: 1.25rem;
    margin: 0 0.75rem;
  }

  .nav-link.active,
  .mobile-nav-link.active {
    /* Don't change the font-weight of the active item – it creates an
 * avoidable layout shift
 */
    color: var(--productPrimary);
  }
  .nav-link {
    color: var(--black);
    margin-right: 1em;
    &:last-child {
      margin-right: 0;
    }
  }
`;

interface NavMenuProps {
  menu: PageType;
  onShiftTab: () => void;
}

const NavMenu = ({ menu, onShiftTab }: NavMenuProps) => {
  const router = useRouter();
  const isRestricted = useIsRestricted(false);
  const [, setModalOpen] = usePleaseLogIn();

  const [isHamburgerMenuExpanded, setHamburgerMenuExpanded] = useState(false);

  return (
    <nav css={navMenuStyles}>
      {/* Traditional menu displayed for clinicians on larger screens */}
      {menu !== 'patients' && (
        <div className="desktop">
          {menuList[menu]?.map((item, index) => {
            if (item.restricted && isRestricted) {
              return (
                <UnstyledButton
                  key={`${item.link}-${item.testid}`}
                  className="nav-link"
                  onClick={() => setModalOpen(true)}
                  data-testid={item.testid}
                >
                  {item.name}
                </UnstyledButton>
              );
            }

            return (
              <Link
                key={`${item.link}-${item.testid}`}
                href={item.link}
                className={`nav-link ${router.pathname === item.link && 'active'}`}
                data-testid={item.testid}
                onKeyDown={(e) => {
                  if (index === 0 && e.key === 'Tab' && e.shiftKey) {
                    onShiftTab();
                  }
                }}
              >
                {item.name}
              </Link>
            );
          })}
        </div>
      )}

      {/* Hamburger menu displayed for clinicians on smaller screens */}
      {menu !== 'patients' && (
        <div className="mobile">
          <Dropdown
            aria-label="Navigation menu"
            menu={{ items: mobileNavMenuDropdownMenu(menu, isRestricted, setModalOpen) }}
            arrow
            trigger={['click']}
            placement="top"
            onOpenChange={setHamburgerMenuExpanded}
          >
            <UnstyledButton
              aria-expanded={isHamburgerMenuExpanded}
              className="hamburger"
              aria-label="hamburger"
              onClick={(e) => e.preventDefault()}
              data-testid="hamburger-menu"
            >
              <MenuOutlined />
            </UnstyledButton>
          </Dropdown>
        </div>
      )}

      {/* Footer navigation for mobile screens handled in footer */}
    </nav>
  );
};

interface UserMenuProps {
  pageType: PageType;
  isNavigationPanelVisible?: boolean;
  userLogout: () => Promise<void>;
}

const poweredByLucyLogoStyles = css`
  height: 25px;

  @media (min-width: 320px) {
    height: 35px;
  }

  @media (min-width: 375px) {
    height: 55px;
  }
`;

export const UserMenu = ({ pageType, isNavigationPanelVisible, userLogout }: UserMenuProps) => {
  const isRestricted = useIsRestricted(false);

  const [isUserMenuExpanded, setUserMenuExpanded] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);

  const { user } = useAuth();
  const avatar = useAvatar(user?.user_guid || '');

  if (pageType === 'login') {
    return (
      <div className="logo" data-testid="only-show-logo">
        <img height={55} src="/static/images/powered-by-lucy.png" alt="" css={poweredByLucyLogoStyles} />
      </div>
    );
  }

  if (isNavigationPanelVisible === false) {
    return null;
  }

  const userMenuDropdownMenu = (): MenuProps['items'] => {
    const menuItems: MenuProps['items'] = [];

    if (!isRestricted) {
      menuItems.push({
        key: 1,
        label: <Link href={pageType === 'patients' ? '/patients/profile' : '/clinician/profile'}>My profile</Link>,
        icon: <UserOutlined />
      });
    }

    if (pageType === 'patients' && !isRestricted) {
      menuItems.push({
        key: 3,
        label: (
          <a rel="noreferrer" target="_blank" href="/patients/legal/?all_policies=0">
            Legal
          </a>
        ),
        icon: <FileTextOutlined />
      });
    }

    menuItems.push({
      key: 4,
      label: (
        <UnstyledButton
          data-testid="logout-button"
          onClick={() => {
            if (sessionStorage.getItem('inCall')) {
              setModalOpen(true);
            } else {
              userLogout().then();
            }
          }}
        >
          Logout
        </UnstyledButton>
      ),
      icon: <LogoutOutlined />
    });

    return menuItems;
  };

  return (
    <>
      <Dropdown
        menu={{ items: userMenuDropdownMenu() }}
        arrow
        placement="topRight"
        trigger={['click']}
        onOpenChange={setUserMenuExpanded}
      >
        <UnstyledButton
          aria-expanded={isUserMenuExpanded}
          aria-label="User menu"
          data-testid="full-user-menu"
          className="profile-button"
          onClick={(e) => e.preventDefault()}
          css={css`
            color: var(--black);
            display: flex;
            align-items: center;
            gap: 0.5em;
            white-space: nowrap;
          `}
        >
          <Avatar src={avatar} size="small" />
          <span
            className="name"
            css={css`
              @media (max-width: 1024px) {
                display: none;
              }
            `}
          >
            {user?.first_name && user?.last_name ? `${user.first_name} ${user.last_name}` : 'Guest Patient'}
            {sessionStorage?.getItem('mocked') === 'true' ? ' (Mocked)' : ''}
          </span>
          <DownOutlined />
        </UnstyledButton>
      </Dropdown>

      <ConfirmationModal
        title="Logout"
        open={isModalOpen}
        okText="Logout"
        closable={false}
        onOk={() => {
          setModalOpen(false);
          userLogout().then();
        }}
        onCancel={() => setModalOpen(false)}
      >
        <p>Logging out will end the call</p>
      </ConfirmationModal>
    </>
  );
};

interface HeadersProps {
  className?: string;
  pageType: PageType;
  pageTitle?: string;
}

const Headers = ({ className = '', pageType, pageTitle = '' }: HeadersProps) => {
  const { t } = useTranslation('common');
  const dispatch = useDispatch();
  const { user } = useAuth();
  const router = useRouter();
  const service_name = user?.service_name || '';
  const queryClient = useQueryClient();
  const { theme } = useTheme();
  const [signInModalOpen, setSignInModalOpen] = usePleaseLogInAndRedirect();
  const [showSkipToContent, setShowSkipToContent] = useState(false);
  const [showAccessibilityOptions, setShowAccessibilityOptions] = useState(false);
  const isExtremeZoom = useExtremeZoom();
  const isStandalone = useIsStandalone();
  const { policiesAccepted } = usePolicies();

  const { pushToLogin } = useDeepLink();

  useEffect(() => {
    if (showSkipToContent) {
      document.getElementById('skip-navigation-link')?.focus();
    }
  }, [showSkipToContent]);
  useEffect(() => {
    if (showAccessibilityOptions) {
      document.getElementById('accessibility-options-link')?.focus();
    }
  }, [showAccessibilityOptions]);

  const userLogout = async () => {
    dispatch(requestSuccess(actionTypes.AUTH_SIGNED_OUT));
    const res = await dispatch(actions.userLogout(queryClient));
    if (res.type === actionTypes.AUTH_LOGOUT_SUCCESS) {
      sessionStorage.removeItem('tour-attributes-set');
      await pushToLogin();
    }
  };

  return (
    <>
      <Head>
        <title>
          {pageTitle
            ? `${t('AppTitleShort')} - ${pageTitle}`
            : `${t('AppTitleShort')} - ${pageType[0].toUpperCase() + pageType.substring(1)}`}
        </title>
        <meta
          property="og:title"
          content={
            pageTitle
              ? `${t('AppTitleShort')} - ${pageTitle}`
              : `${t('AppTitleShort')} - ${pageType[0].toUpperCase() + pageType.substring(1)}`
          }
          key="title"
        />
      </Head>

      <header
        className={className}
        css={
          pageType === 'patients'
            ? css`
                width: 100%;
                flex-wrap: wrap;
                flex-shrink: 0;
              `
            : ''
        }
      >
        <div
          css={css`
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;

            padding: 1em;
            background: var(--white);
            color: var(--black);
          `}
        >
          {pageType === 'login' ? (
            <div className="logo">
              <img height={service_name ? 35 : 55} src={theme.logo} alt="logo" />
              <br />
              {service_name && <span className="serviceName" dangerouslySetInnerHTML={{ __html: service_name }} />}
            </div>
          ) : (
            <Link
              href={pageType === 'patients' ? '/patients' : '/clinician'}
              aria-label="Dashboard"
              css={css`
                color: var(--black);
                font-weight: 500;
                font-size: 12px;
                width: 150px;
                white-space: pre;
              `}
              tabIndex={0}
              onKeyDown={(e) => {
                if (e.key === 'Tab' && !e.shiftKey && !showSkipToContent) {
                  e.preventDefault();
                  e.stopPropagation();

                  setShowSkipToContent(true);
                }
              }}
            >
              <img height={service_name ? 35 : 55} src={theme.logo} alt="" />
              <br />
              {service_name && (
                <span
                  css={css`
                    position: relative;
                    font-weight: bold;
                    @media (max-width: 576px) {
                      font-size: 10px;
                    }
                  `}
                  dangerouslySetInnerHTML={{ __html: service_name }}
                />
              )}
            </Link>
          )}
          <a
            css={css`
              position: absolute;
              left: 1rem;
              top: 80px;
              padding: 16px 8px;
              font-size: 16px;
              border-radius: 6px;
              border: 2px solid var(--productPrimaryDark);
              text-align: center;
              z-index: ${showSkipToContent ? 100 : -200};
              background-color: var(--white);
              opacity: ${showSkipToContent ? 1 : 0};
            `}
            id="skip-navigation-link"
            href="#page-content"
            tabIndex={0}
            onKeyDown={(e) => {
              if (e.key === 'Tab' || e.key === 'Enter') {
                setShowSkipToContent(false);

                if (e.key === 'Tab' && !e.shiftKey) {
                  setShowAccessibilityOptions(true);
                }
              }
            }}
            onFocus={() => {
              setShowSkipToContent(true);
            }}
            onBlur={() => {
              setShowSkipToContent(false);
            }}
          >
            Skip to Content
          </a>

          {pageType !== 'login' && pageType === 'clinician' && (
            <NavMenu menu={pageType} onShiftTab={() => setShowAccessibilityOptions(true)} />
          )}
          <div
            css={css`
              display: flex;
              align-items: center;
              gap: 10px;

              padding: 1em;
              background: var(--white);
              color: var(--black);
            `}
          >
            {isExtremeZoom && pageType === 'patients' && <NavigationHeader />}

            <UserMenu pageType={pageType} userLogout={userLogout} isNavigationPanelVisible={!!policiesAccepted} />

            <PleaseLogIn
              open={!!signInModalOpen.pleaseLogIn}
              onCancel={() => {
                if (signInModalOpen.pleaseLogInRedirect) {
                  return router.push(signInModalOpen.pleaseLogInRedirect);
                }

                return setSignInModalOpen({ pleaseLogIn: false });
              }}
            />

            {isStandalone && (
              <Button
                onClick={() => window.location.reload()}
                icon={<ReloadOutlined />}
                shape="circle"
                type="primary"
                aria-label="Reload"
                title="Reload"
                data-testid="refresh-window-button"
                css={css`
                  margin-left: 0.5em;
                `}
              />
            )}
          </div>
        </div>

        {pageType === 'patients' && !!policiesAccepted && !isExtremeZoom && <NavigationHeader />}
      </header>
    </>
  );
};

export default Headers;
